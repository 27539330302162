<template>
  <div class="tipsmask">
    <el-icon class="close" @click="closeTips"><Close /></el-icon>
    <p>
      <el-icon><InfoFilled /></el-icon>
      非VIP用户只能在当前对话框进行对话哦，如果想创建多个对话请升到任一款VIP！
    </p>
    <p>
      <el-icon><InfoFilled /></el-icon>提问框前的齿轮
      <img src="../../assets/images/setting.png" />
      是会话设置，可以设置上下文是否连续以及不同模型对应的回复字数上限，记住要根据不同情况合理使用tokens！
    </p>
    <p>
      <el-icon><InfoFilled /></el-icon>1000 tokens ≈ 500个汉字 ≈ 750个英文单词
    </p>
  </div>
</template>

<script setup>
import { useStore } from 'vuex'
const store = useStore()
const closeTips = () => {
  store.commit('common/setTipsMask', false)
  localStorage.setItem('tipsmask', true)
}
</script>
<style lang="scss" scoped>
.tipsmask {
  background: #fdf6ec;
  border: 1px solid #faecd8;
  padding: 15px 19px;
  position: fixed;
  top: calc(#{$top-height} + 60px);
  left: $width;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  z-index: 999;
  .close {
    color: #a8abb2;
    font-size: 18px;
    position: absolute;
    top: 15px;
    right: 15px;
    cursor: pointer;
  }
  p {
    width: 850px;
    color: #4f4f4f;
    font-size: 14px;
    display: flex;
    align-items: center;
    .el-icon {
      color: #e6a23c;
      font-size: 16px;
      font-style: normal;
      vertical-align: middle;
      margin-right: 4px;
    }
    img {
      width: 20px;
      height: 20px;
    }
  }
}
</style>
